import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import api from '../../services/api'
import { displayMessage } from '../../helpers/messages'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { SL_Button } from '../../components/buttons/Button'
import { SL_Input } from '../../components/input/Input'
import { AuthContext } from '../../context/AuthContext/AuthContext'
import styles from '../../services/styles'
import { FogotPasswordModal } from './components/FogotPasswordModal'

export const Login = () => {
    const { setLoggedUser, setAuthorizationToken } = useContext(AuthContext)

    const navigate = useNavigate()

    const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [showSuccessInfoInForgotPasswordModal, setShowSuccessInfoInForgotPasswordModal] =
        useState(false)

    const { showAlert } = useContext(GeneralContext)

    const onFinish = async ({ email, password }) => {
        try {
            const { data } = await api.authClient.authenticate({ email, password })
            const { client_user, token } = data

            setAuthorizationToken(token, true)
            setLoggedUser(client_user)

            if (client_user?.must_change_password) {
                navigate('/change-password')
            }

            navigate('/reports')
        } catch (e) {
            showAlert('LOGIN_ERROR')
        }
    }

    const onCloseModalForgotPassword = () => {
        if (showLoading) {
            return
        }

        setShowSuccessInfoInForgotPasswordModal(false)
        setIsForgotPasswordModalOpen(false)
    }

    const handleOkPasswordRecovery = async email => {
        if (!email) {
            showAlert('INVALID_EMAIL')
            return
        }

        setShowLoading(true)

        try {
            const { data } = await api.authClient.resetPasswordSolicitation(email)

            setShowSuccessInfoInForgotPasswordModal(data.success)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    return (
        <>
            {isForgotPasswordModalOpen && (
                <FogotPasswordModal
                    onOk={handleOkPasswordRecovery}
                    onClose={onCloseModalForgotPassword}
                    showLoading={showLoading}
                    showSuccessInfoInForgotPasswordModal={showSuccessInfoInForgotPasswordModal}
                />
            )}

            <>
                <div className="text-sl-gray-50 font-bold mb-4 text-xl ss:text-2xl shadow-title montserrat-light-font">
                    {displayMessage('LOGIN_TITLE')}
                </div>

                <Form
                    onFinish={onFinish}
                    className="max-w-[400px] w-full"
                    layout="vertical"
                    requiredMark={false}
                >
                    <div className="flex flex-col gap-1">
                        <div className="text-sl-gray-50 font-bold shadow-title montserrat-light-font">
                            {displayMessage('EMAIL')}
                        </div>

                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: displayMessage('DISPLAY_EMAIL') }]}
                            className="mb-4 montserrat-light-font"
                        >
                            <SL_Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder={displayMessage('ENTER_EMAIL')}
                                className={'w-full font-bold shadow-md montserrat-light-font'}
                            />
                        </Form.Item>
                    </div>

                    <div className="flex flex-col gap-1 mt-1">
                        <div className="text-sl-gray-50 font-bold shadow-title montserrat-light-font">
                            {displayMessage('PASSWORD')}
                        </div>

                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: displayMessage('DISPLAY_PASSWORD') },
                            ]}
                            className="mb-6 montserrat-light-font"
                        >
                            <SL_Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                className={'w-full font-bold shadow-md montserrat-light-font'}
                                placeholder={displayMessage('ENTER_PASSWORD')}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item hasFeedback className="mb-3 mt-2">
                        <SL_Button
                            type="primary"
                            className={'w-full font-bold shadow-md montserrat-light-font'}
                            htmlType="submit"
                            style={{ backgroundColor: styles.colors.primary['400'] }}
                        >
                                <div  className={'text-gray-50 font-bold shadow-title montserrat-light-font'}>
                                    {displayMessage('ENTER')}
                                </div>
                        </SL_Button>
                    </Form.Item>

                    <Form.Item className="m-0">
                        <div className="flex justify-center">
                            <SL_Button
                                type="link"
                                onClick={() => {
                                    setIsForgotPasswordModalOpen(true)
                                }}
                            >
                                <div  className={'text-gray-50 font-bold shadow-title montserrat-light-font'}>
                                    {displayMessage('RECOVER_PASSWORD')}
                                </div>
                            </SL_Button>
                        </div>
                    </Form.Item>
                </Form>
            </>
        </>
    )
}
