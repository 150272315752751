import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'

export default function CustomToolbar(props) {
    const isDataValid = props?.data && props?.data.length > 0

    return (
        <GridToolbarContainer>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ delimiter: ';' }} />

                <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                    {props.children}
                </div>
            </div>
        </GridToolbarContainer>
    )
}
