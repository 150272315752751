import { useContext, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { SL_ClipLoader } from '../../../../../components/loading/ClipLoader'
import api from '../../../../../services/api'
import {
    Card,
    Checkbox,
    Collapse,
    Divider,
    Image,
    List,
    Popconfirm,
    Tabs,
    Tag,
    Tooltip,
    Typography,
} from 'antd'
import { displayMessage } from '../../../../../helpers/messages'
import EFollowUpStatus from '../../../../../enums/EFollowUpStatus'
import { SL_Button } from '../../../../../components/buttons/Button'
import { GeneralContext } from '../../../../../context/GeneralContext/GeneralContext'
import ModalChat from './ModalChat'
import { ModalCreateMessage } from './ModalCreateMessage'
import { SL_Grid } from '../../../../../components/grid/SL_Grid'
import followup_approvals_grid_columns from '../grid_columns/followup_approvals_grid_columns'
import CustomToolbar from '../../../../../components/grid/CustomToolbar'
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { SL_Select } from '../../../../../components/selects/Select'
import { ERiskTypes } from '../../../../../enums/ERiskTypes'
import './FollowUpDetails.css'
import { AuthContext } from '../../../../../context/AuthContext/AuthContext'

const { Text } = Typography
const max_attachment_characters = 120
const max_last_message_characters = 90
const max_risk_title_characters = 140

export function FollowUpDetails({
    externFollowUpId,
    externalReportId,
    followup_report_risk_id_param = null,
}) {
    const { loggedUser } = useContext(AuthContext)

    const { followUpId, reportId } = useParams()
    const [searchParams] = useSearchParams()
    const followupReportRiskId = searchParams.get('followup_report_risk_id')
    let eRiskTypes = null

    const { mobile_mode } = useContext(GeneralContext)

    const [followUp, setFollowUp] = useState(null)

    const [loading, setLoading] = useState(false)
    const [previousFollowUp, setPreviousFollowUp] = useState(null)

    const [isModalConversationOpen, setIsModalConversationOpen] = useState(false)
    const [currentFollowupReportRisk, setCurrentFollowupReportRisk] = useState([])
    const [selectedAttachment, setSelectedAttachment] = useState(null)
    const [isModalCreateMessageIsOpen, setIsModalCreateMessageIsOpen] = useState(false)
    const [followupApprovals, setFollowupApprovals] = useState([])
    const [selectedRow, setSelectedRow] = useState(null)
    const [activeKey, setActiveKey] = useState(followup_report_risk_id_param)
    const [followupReportRisks, setFollowupReportRisks] = useState([])

    useEffect(() => {
        loadFollowUp(), 
        loadFollowUpReportRisks()
    }, [])

    useEffect(() => {
        if (followupReportRiskId) {
            setActiveKey(followupReportRiskId)
            const followup_report_risk = followupReportRisks.find(
                report_risk => report_risk?.id == followupReportRiskId
            )
            setCurrentFollowupReportRisk(followup_report_risk)
        }
    }, [followupReportRiskId, followupReportRisks])

    useEffect(() => {
        if (followup_report_risk_id_param) {
            setActiveKey(followup_report_risk_id_param)
        }
    }, [followup_report_risk_id_param])

    useEffect(() => {
        if (followUp) {
            loadPreviousFollowup()
        }
    }, [followUp])

    useEffect(() => {
        loadFollowupApprovals()
    }, [currentFollowupReportRisk])

    useEffect(() => {
        if (externFollowUpId) {
            loadFollowUpReportRisks()
        }
    }, [externFollowUpId])

    const loadFollowupApprovals = async (load = true) => {
        if (load) setLoading(true)
        try {
            const { data } = await api.followupApprovals.list({
                followup_report_risk_id: currentFollowupReportRisk?.id,
                order_by_date_desc: true,
            })
            setFollowupApprovals(data)
            setSelectedRow(data.find(fu => fu.is_attachment_selected))
        } catch (error) {
            console.log(error)
        } finally {
            if (load) setLoading(false)
        }
    }

    const loadPreviousFollowup = async (load = true) => {
        if (load) setLoading(true)
        try {
            const { data } = await api.followUps.list({ report_id: reportId ?? externalReportId })
            const current_followup_sequence = followUp?.sequence
            const previous_follow_up = data.find(
                followup => followup.sequence == current_followup_sequence - 1
            )
            if (previous_follow_up) {
                setPreviousFollowUp(previous_follow_up)
            }
        } catch (error) {
            console.log(error)
        } finally {
            if (load) setLoading(false)
        }
    }

    const loadFollowUp = async () => {
        try {
            const { data } = await api.followUps.find(followUpId ?? externFollowUpId)
            setFollowUp(data)
            let result = await createFollowUpReportRiskSequence(data.FollowupReportRisks)

            setFollowupReportRisks(result)
            if (followup_report_risk_id_param)
                handleOnCollapseChange([followup_report_risk_id_param], data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadFollowUpReportRisks = async () => {
        try {
            if (!eRiskTypes) {
                const enumRes = await api.enums.find('ERiskTypes')
                eRiskTypes = enumRes.data
            }
            let data
            if (loggedUser.type == 1) {
                const response = await api.followupReportRisk.list({
                    followup_id: followUpId ?? externFollowUpId,
                })
                data = response.data
            } else {
                const response = await api.followupReportRisk.list({
                    followup_id: followUpId ?? externFollowUpId,
                    client_user_id: loggedUser.id,
                })
                data = response.data
            }
            if (data.length >0) {
            let result = await createFollowUpReportRiskSequence(data)
             setFollowupReportRisks(result)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const renderList = (dataSource, emptyText) => {
        return (
            <>
                <List
                    size="small"
                    locale={{ emptyText }}
                    className="rounded p-0 m-0"
                    itemLayout="horizontal"
                    dataSource={dataSource}
                    renderItem={item => (
                        <List.Item className="p-2">
                            <p>{item.description}</p>
                        </List.Item>
                    )}
                />
            </>
        )
    }

    const handleOnCollapseChange = key => {
        setActiveKey(key)
        if (key.length == 0) {
            setCurrentFollowupReportRisk(null)
            return
        }
        const followup_report_risk = followupReportRisks.find(
            report_risk => report_risk?.id == key[0]
        )

        setCurrentFollowupReportRisk(followup_report_risk)
    }

    const returnFollowUpReportRiskStatus = followup_report_risk => {
        let status
        if (previousFollowUp) {
            const prev_followup_report_risk = previousFollowUp.FollowupReportRisks.find(
                prevFollowUp => prevFollowUp.report_risk_id == followup_report_risk.report_risk_id
            )
            if (prev_followup_report_risk && followup_report_risk.status == null) {
                followup_report_risk = prev_followup_report_risk
            }
        }
        if (followup_report_risk.status != null) {
            status = Object.values(EFollowUpStatus).find(
                e => e.code == followup_report_risk.status
            )?.description
        } else {
            status = displayMessage('NO_STATUS_ALREADY_SELECTED')
        }

        return <p>{status}</p>
    }

    const getSequenceDescription = async data => {
        const risk_type = data?.Risk?.RiskKind?.RiskActivity?.RiskType
       
        const risk_type_acronym = data.recommendation_number ? data.recommendation_number : Object.values(eRiskTypes).find(
                e => risk_type?.code == e.code
            )?.acronym
        return risk_type_acronym
    }

    const createFollowUpReportRiskSequence = async riskKindId => {
        let sequence,
            max_one_digit = 9,
            max_two_digits = 99
        const FollowUpFormater = []

        if (!Array.isArray(riskKindId)) {
            console.error('riskKindId is not an array or is undefined')
            return FollowUpFormater
        }
        const ReportRisk = riskKindId.map(e => ({
            ...e.ReportRisk,
            originalStatus: e.status, // Adiciona o status original (se remover, pega o status do risco e da ruim)
            originalId: e.id, //Adicinona id original (as msg precisam dele)
            Attachment: e.Attachment,
            original_attachment_id: e.attachment_id,
            client_user_associated: e.ClientUser,
        }))
        const sortedRisks = ReportRisk.sort((a, b) => a.id - b.id)

        for (let i = 0; i < sortedRisks.length; i++) {
            const report_risk = sortedRisks[i]
            if (report_risk.is_from_app) {
                continue
            }

            const risk_type_acronym = await getSequenceDescription(report_risk)
            if (report_risk.sequence) {
                sequence = report_risk.sequence.toString().padStart(3, '0')
            } else {
                if (i < max_one_digit) {
                    sequence = `00${i + 1}`
                } else if (i < max_two_digits) {
                    sequence = `0${i + 1}`
                } else {
                    sequence = `${i + 1}`
                }
            }
            FollowUpFormater.push({
                ...report_risk,
                sequence: `${risk_type_acronym}${sequence}`,
                FollowupApprovals: sortedRisks[i].FollowupApprovals ?? [],
                status: sortedRisks[i].originalStatus, // Mantém o status original
                id: report_risk.originalId, //mantém o id original
                Attachment: report_risk.Attachment,
                attachment_id: sortedRisks[i].original_attachment_id,
                client_user_associated: report_risk.client_user_associated,
            })
        }
        return FollowUpFormater
    }
    const extraButtons = {
        field: 'buttons',
        headerName: displayMessage('SELECT_MESSAGE'),
        width: 160,
        align: 'center',
        renderCell: function (params) {
            const { row } = params
            return (
                <div className="flex gap-x-3" style={{ padding: 2 }}>
                    {selectedRow?.id == row?.id ? (
                        <Checkbox
                            color="primary"
                            checked={selectedRow?.id == row?.id}
                            disabled={true}
                        />
                    ) : (
                        <Popconfirm
                            placement="topRight"
                            title={displayMessage('SELECT_MESSAGE')}
                            description={
                                <div className="w-[300px]">
                                    {displayMessage('INFO_SELECT_MESSAGE')}
                                </div>
                            }
                            okText={displayMessage('YES')}
                            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                        >
                            <Checkbox
                                color="primary"
                                checked={selectedRow?.id == row?.id}
                                disabled={true}
                            />
                        </Popconfirm>
                    )}
                </div>
            )
        },
    }

    const selectAttachment = {
        field: 'select_attachment',
        headerName: displayMessage('SELECT_ATTACHMENT_TO_REPORT'),
        width: 250,
        align: 'center',
        renderCell: function (params) {
            const { row } = params
            const max_attachment_name_length = 20
            const items = row.Attachments.map(attachment => {
                return {
                    value: attachment?.id,
                    label:
                        attachment.name.length >= max_attachment_name_length
                            ? `${attachment.name.slice(0, max_attachment_name_length)}...`
                            : attachment.name,
                    full_name: attachment.name,
                }
            })
            return (
                <div className="flex gap-x-3" style={{ padding: 2 }}>
                    <Tooltip
                        title={
                            items.find(attachment => attachment.value == selectedAttachment)
                                ?.full_name
                        }
                        overlayStyle={{ maxWidth: 500 }}
                        overlayInnerStyle={{
                            backgroundColor: 'white',
                            color: 'black',
                            textAlign: 'justify',
                        }}
                    >
                        <SL_Select
                            options={items}
                            value={
                                selectedRow?.id == row.id
                                    ? selectedRow?.FollowupReportRisk?.Attachment?.id
                                    : null
                            }
                            disabled={true}
                            placeholder={displayMessage('SELECT_ATTACHMENT')}
                            allowClear
                        />
                    </Tooltip>
                </div>
            )
        },
    }

    const handleOnDownload = async attachment => {
        setLoading(true)
        try {
            const { data } = await api.attachments.openAttachment(attachment.id, {
                responseType: 'blob',
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(data)
            link.download = `${attachment.name}`
            link.click()
            const parent = link.parentNode
            if (link && parent) {
                parent.removeChild(link)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const followup_report_risks_collapse_items = followupReportRisks.map(
        (followup_report_risk, index) => {
            const last_message_sent = followupApprovals.find(
                approval => approval.followup_report_risk_id === followup_report_risk.id
            )
            const is_user_client_returned = followup_report_risk?.FollowupApprovals.find(
                approval => approval.client_id
            )
            const last_user_sent_message = last_message_sent?.ClientUser
                ? last_message_sent?.ClientUser
                : last_message_sent?.User
            const e = followup_report_risk
            const { Attachment } = followup_report_risk
            const riskTitleWithAcronym = `${followup_report_risk.sequence} - ${e?.Risk?.title}`
            return {
                key: followup_report_risk?.id,
                label: (
                    <>
                        <div className="flex flex-row gap-3">
                            {riskTitleWithAcronym.length > max_risk_title_characters ? (
                                <Tooltip
                                    title={riskTitleWithAcronym}
                                    overlayStyle={{ maxWidth: 700 }}
                                    overlayInnerStyle={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        textAlign: 'justify',
                                    }}
                                >
                                    <div>{`${riskTitleWithAcronym.slice(
                                        0,
                                        max_risk_title_characters
                                    )}...`}</div>
                                </Tooltip>
                            ) : (
                                <span className="ml-2">{riskTitleWithAcronym}</span>
                            )}
                            <div>
                                {!is_user_client_returned && (
                                    <Tag color={'#8e2424'}>
                                        {displayMessage('TAG_RETURN_FOLLOW_UP_REPORT_RISK')}
                                    </Tag>
                                )}
                                <Tag
                                    color={
                                        followup_report_risk?.status ==
                                            EFollowUpStatus.NOT_SERVED.code ||
                                        !followup_report_risk?.status
                                            ? '#d04848'
                                            : '#027878'
                                    }
                                >
                                    {Object.values(EFollowUpStatus).find(
                                        status => status.code == followup_report_risk?.status
                                    )?.description ?? displayMessage('WITHOUT_STATUS')}
                                </Tag>
                            </div>
                        </div>
                        {last_message_sent && (
                            <div className="flex flex-row w-full">
                                <span className="ml-5 font-bold">
                                    {displayMessage('LAST_MESSAGE_SENT')}:
                                </span>
                                {last_message_sent?.description?.length >
                                max_last_message_characters ? (
                                    <Tooltip
                                        title={last_message_sent?.description}
                                        overlayStyle={{ maxWidth: 700 }}
                                        overlayInnerStyle={{
                                            backgroundColor: 'white',
                                            color: 'black',
                                            textAlign: 'justify',
                                        }}
                                    >
                                        <span className="ml-2">{`${last_message_sent?.description.slice(
                                            0,
                                            max_last_message_characters
                                        )}...`}</span>
                                    </Tooltip>
                                ) : (
                                    <span className="ml-2">{last_message_sent?.description}</span>
                                )}
                                <span className="ml-2 font-bold">
                                    (enviado por:{' '}
                                    {`${last_user_sent_message?.first_name} ${last_user_sent_message?.last_name}`}
                                    )
                                </span>
                            </div>
                        )}
                    </>
                ),
                children: loading ? null : (
                    <div>
                        <div className={`flex w-full ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                            {mobile_mode ? (
                                <div className="flex flex-col w-full">
                                    <div className="flex flex-row w-full justify-between">
                                        <div className="flex w-full items-center justify-start">
                                            <div>{displayMessage('STATUS')}:</div>
                                            <div className="ml-2">
                                                {returnFollowUpReportRiskStatus(followup_report_risk)}
                                            </div>
                                            <div className="p-1 ml-4">
                                                {displayMessage('CLIENT_USER_ASSOCIATED')}:
                                            </div>
                                            <div className="ml-4">
                                                {followup_report_risk?.client_user_associated ? (
                                                    <p>
                                                        {followup_report_risk?.client_user_associated?.first_name}{' '}
                                                        {followup_report_risk?.client_user_associated?.last_name}
                                                    </p>
                                                ) : (
                                                    <p>{displayMessage('EMPTY_01')}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {Attachment?.file_type.includes('image') ? (
                                        <div className={`flex w-full items-center justify-start mt-5`}>
                                            <div className="mr-5">{displayMessage('IMAGE')}:</div>
                                            <div className="flex justify-center">
                                                <Tooltip
                                                    title={displayMessage('CLICK_IMAGE_TO_OPEN')}
                                                >
                                                    <Image
                                                        src={api.attachments.imageDownload(
                                                            followup_report_risk?.attachment_id
                                                        )}
                                                        width={45}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={`flex w-full items-center justify-start mt-5`}>
                                            <div className="mr-5">
                                                {displayMessage('DOCUMENT')}:
                                            </div>
                                            {Attachment ? (
                                                <a
                                                    className="text-center"
                                                    key={Attachment?.id}
                                                    href={Attachment?.url}
                                                    download
                                                    style={{
                                                        display: 'block',
                                                        marginBottom: '4px',
                                                    }}
                                                    onClick={() => handleOnDownload(Attachment)}
                                                >
                                                    <Tooltip
                                                        title={Attachment?.name}
                                                        overlayStyle={{ maxWidth: 500 }}
                                                        overlayInnerStyle={{
                                                            backgroundColor: 'white',
                                                            color: 'black',
                                                            textAlign: 'justify',
                                                        }}
                                                    >
                                                        {Attachment?.name.length >
                                                        max_attachment_characters
                                                            ? `${Attachment?.name.slice(
                                                                  0,
                                                                  max_attachment_characters
                                                              )}...`
                                                            : Attachment?.name}
                                                    </Tooltip>
                                                </a>
                                            ) : (
                                                displayMessage('EMPTY_01')
                                            )}
                                        </div>
                                    )}
                                    <div className="flex flex-row mt-3">
                                        <SL_Button
                                            onClick={() => {
                                                setIsModalConversationOpen(true)
                                            }}
                                        >
                                            {displayMessage('CLIENT_FOLLOWUP')}
                                        </SL_Button>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-row w-full justify-between">
                                    <div className="flex w-1.5/4 mr-5 min-w-56 items-center justify-center">
                                        <div>{displayMessage('STATUS')}:</div>
                                        <div className="ml-2">
                                            {returnFollowUpReportRiskStatus(followup_report_risk)}
                                        </div>
                                        <div className="p-1 ml-4">
                                            {displayMessage('CLIENT_USER_ASSOCIATED')}:
                                        </div>
                                        <div className="ml-4">
                                            {followup_report_risk?.client_user_associated ? (
                                                <p>
                                                    {followup_report_risk?.client_user_associated?.first_name}{' '}
                                                    {followup_report_risk?.client_user_associated?.last_name}
                                                </p>
                                            ) : (
                                                <p>{displayMessage('EMPTY_01')}</p>
                                            )}
                                        </div>
                                    </div>
                                    {Attachment?.file_type.includes('image') ? (
                                        <div className={`flex w-0.5/4 items-center justify-center`}>
                                            <div className="mr-5">{displayMessage('IMAGE')}:</div>
                                            <div className="flex justify-center">
                                                <Tooltip
                                                    title={displayMessage('CLICK_IMAGE_TO_OPEN')}
                                                >
                                                    <Image
                                                        src={api.attachments.imageDownload(
                                                            followup_report_risk?.attachment_id
                                                        )}
                                                        width={45}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={`flex w-0.5/4 items-center justify-center`}>
                                            <div className="mr-5">
                                                {displayMessage('DOCUMENT')}:
                                            </div>
                                            {Attachment ? (
                                                <a
                                                    className="text-center"
                                                    key={Attachment?.id}
                                                    href={Attachment?.url}
                                                    download
                                                    style={{
                                                        display: 'block',
                                                        marginBottom: '4px',
                                                    }}
                                                    onClick={() => handleOnDownload(Attachment)}
                                                >
                                                    <Tooltip
                                                        title={Attachment?.name}
                                                        overlayStyle={{ maxWidth: 500 }}
                                                        overlayInnerStyle={{
                                                            backgroundColor: 'white',
                                                            color: 'black',
                                                            textAlign: 'justify',
                                                        }}
                                                    >
                                                        {Attachment?.name.length >
                                                        max_attachment_characters
                                                            ? `${Attachment?.name.slice(
                                                                  0,
                                                                  max_attachment_characters
                                                              )}...`
                                                            : Attachment?.name}
                                                    </Tooltip>
                                                </a>
                                            ) : (
                                                displayMessage('EMPTY_01')
                                            )}
                                        </div>
                                    )}
                                    <div className={`flex w-0.5/4 items-center justify-center}`}>
                                        <SL_Button
                                            onClick={() => {
                                                setIsModalConversationOpen(true)
                                            }}
                                        >
                                            {displayMessage('CLIENT_FOLLOWUP')}
                                        </SL_Button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`flex ${
                                mobile_mode ? 'flex-col' : 'flex-row'
                            } w-full gap-x-5`}
                        >
                            <Card
                                title={displayMessage('PROBABILITY_X_GRAVITY')}
                                className="w-full max-w-full bg-sl-gray-50 mt-5"
                            >
                                <div className="flex flex-row mt-3">
                                    <div>{displayMessage('AFFECTED_PILLAR')}:</div>
                                    <div className="text-gray-400 ml-2">
                                        {e?.AffectedPillar?.title}
                                    </div>
                                </div>

                                {/* <div className="flex flex-row mt-3">
                                    <div>{displayMessage('INSURANCE_COMPANY')}:</div>
                                    <div className="text-gray-400 ml-2">
                                        {e?.InsuranceCompany?.name || displayMessage('EMPTY_01')}
                                    </div>
                                </div> */}

                                <div className="flex flex-row mt-3">
                                    <div>{displayMessage('CONTROL_MESURE')}:</div>
                                    <div className="text-gray-400 ml-2">
                                        {e?.ControlMesure?.name} | {e?.ControlMesure?.description}
                                    </div>
                                </div>

                                <div className="flex flex-row mt-3">
                                    <div>{displayMessage('SEVERITY')}:</div>
                                    <div className="text-gray-400 ml-2">
                                        {e?.Severity?.name} | {e?.Severity?.description}
                                    </div>
                                </div>

                                <div className="flex flex-row mt-3">
                                    <div>{displayMessage('FREQUENCY')}:</div>
                                    <div className="text-gray-400 ml-2">
                                        {e?.Frequency?.name} | {e?.Frequency?.description}
                                    </div>
                                </div>

                                <div className="flex flex-row mt-3">
                                    <div>{displayMessage('COVERAGE_SCALE')}:</div>
                                    <div className="text-gray-400 ml-2">
                                        {e?.CoverageScale?.name} | {e?.CoverageScale?.description}
                                    </div>
                                </div>

                                <div className="flex flex-row mt-3">
                                    <div className="flex flex-row">
                                        <div>{displayMessage('PRIORITY_LEVEL')}:</div>
                                        <div className="text-gray-400 ml-2">
                                            {e?.PriorityLevel?.name} |{' '}
                                            {e?.PriorityLevel?.description}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: 10,
                                            width: 20,
                                            height: 20,
                                            borderRadius: 10,
                                            backgroundColor: e?.PriorityLevel?.color,
                                        }}
                                    ></div>
                                </div>

                                <div className="flex flex-row mt-3 w-full">
                                    <div>{displayMessage('DETECTION')}:</div>
                                    <div className="text-gray-400 ml-2">
                                        {e?.Detection?.name} | {e?.Detection?.description}
                                    </div>
                                </div>
                            </Card>
                            <div className="flex flex-col w-full gap-y-2">
                                <Card title={displayMessage('RISK')} className="bg-sl-gray-50 mt-5">
                                    <div className="flex flex-row p-1 mt-2">
                                        <div className="ml-2">{e?.Risk?.title}</div>
                                    </div>
                                </Card>
                                <Card
                                    title={displayMessage('RECOMMENDATION')}
                                    className="w-full bg-sl-gray-50 p-0 overflow-hidden"
                                >
                                    <div className="flex flex-row p-1 mt-2">
                                        <div className="ml-2">{e?.Recommendation?.description}</div>
                                    </div>
                                </Card>
                                <Card
                                    className="w-full bg-sl-gray-50 p-0 min-h-[200px] max-h-[200px] overflow-hidden"
                                    title={displayMessage('NORMATIVES')}
                                >
                                    <Tooltip
                                        title={renderList(
                                            e?.Risk?.Normatives,
                                            displayMessage('NO_ONE_NORMATIVE_FOR_THIS_RISK')
                                        )}
                                        placement="bottom"
                                        overlayStyle={{ maxWidth: 500 }}
                                        overlayInnerStyle={{
                                            backgroundColor: 'white',
                                            color: 'black',
                                            textAlign: 'justify',
                                        }}
                                    >
                                        {renderList(
                                            e?.Risk?.Normatives,
                                            displayMessage('NO_ONE_NORMATIVE_FOR_THIS_RISK')
                                        )}
                                    </Tooltip>
                                </Card>
                            </div>
                        </div>
                        <div className="mt-3">
                            <Text className="text-primary-600 text-base" strong>
                                {displayMessage('CLIENT_FOLLOWUP')}
                            </Text>
                            <SL_Grid
                                getRowHeight={() => 'auto'}
                                sortingMode={'server'}
                                components={{
                                    Toolbar: () => (
                                        <>
                                            <div className="flex justify-between flex-row ss:flex-row">
                                                <CustomToolbar />
                                                {!followUp?.end_date && (
                                                    <div
                                                        className={`flex ${
                                                            mobile_mode ? 'flex-col' : 'flex-row'
                                                        }`}
                                                    >
                                                        <SL_Button
                                                            icon={<PlusOutlined />}
                                                            className="my-1 mr-1"
                                                            onClick={() =>
                                                                setIsModalCreateMessageIsOpen(true)
                                                            }
                                                        >
                                                            {displayMessage('NEW_MESSAGE')}
                                                        </SL_Button>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ),
                                }}
                                rows={followupApprovals ?? []}
                                columns={[
                                    extraButtons,
                                    ...followup_approvals_grid_columns(handleOnDownload).concat(
                                        selectAttachment
                                    ),
                                ]}
                                disableFilter={false}
                                className="flex-1 min-h-[50vh] max-h-[100vh] mt-2"
                                getRowClassName={params =>
                                    selectedRow?.id == params?.id ? 'selected-message-row' : ''
                                }
                            />
                        </div>
                        {isModalCreateMessageIsOpen && !followUp?.end_date && (
                            <ModalCreateMessage
                                isOpen={isModalCreateMessageIsOpen}
                                onClose={() => {
                                    setIsModalCreateMessageIsOpen(false)
                                    loadFollowupApprovals(false)
                                    loadFollowUp()
                                }}
                                followupReportRisk={currentFollowupReportRisk}
                            />
                        )}
                        {isModalConversationOpen && (
                            <ModalChat
                                loadFollowUp={loadFollowUp}
                                isFollowupFinished={followUp?.end_date}
                                onCloseChat={() => {
                                    setActiveKey(currentFollowupReportRisk?.id)
                                    setIsModalConversationOpen(false)
                                    loadFollowUp()
                                    loadFollowupApprovals(false)
                                }}
                                followupReportRiskId={currentFollowupReportRisk?.id}
                                recommendation={
                                    currentFollowupReportRisk?.Recommendation?.description
                                }
                            />
                        )}
                    </div>
                ),
            }
        }
    )

    return (
        <>
            <span className="text-lg">{displayMessage('RISKS')}</span>
            <Divider className="mt-3" />
            <div className="flex w-full">
                {followup_report_risks_collapse_items &&
                followup_report_risks_collapse_items.filter(item => item.children != null) ? (
                    <Collapse
                        defaultActiveKey={[followup_report_risk_id_param]}
                        activeKey={activeKey}
                        ghost
                        accordion
                        size="large"
                        className="w-full"
                        items={followup_report_risks_collapse_items}
                        onChange={handleOnCollapseChange}
                    />
                ) : (
                    <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                        <SL_ClipLoader loading={true} />
                    </div>
                )}
            </div>
        </>
    )
}
