import { DateTime } from 'luxon'
import { displayMessage } from '../../../../../helpers/messages'

export const follow_ups_grid_columns = [
    {
        field: 'sequence',
        headerName: displayMessage('SEQUENCE'),
        type: 'string',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.sequence != null || row.sequence != '') return row.sequence
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'event_date',
        headerName: displayMessage('CREATED_AT'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.event_date)
                return DateTime.fromISO(row.event_date).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'end_date',
        headerName: displayMessage('FINALIZATION_DATE'),
        type: 'date',
        width: 200,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.end_date) return DateTime.fromISO(row.end_date).toFormat('dd/MM/yyyy HH:mm:ss')
            return displayMessage('EMPTY_01')
        },
    },
    {
        field: 'finalization_date_programed',
        headerName: displayMessage('AUTOMATIC_FINALIZATION_DATE'),
        type: 'date',
        width: 250,
        valueFormatter: params => {
            const row = params.api.getRow(params.id)
            if (row.finalization_date_programed)
                return DateTime.fromISO(row.finalization_date_programed).toFormat(
                    'dd/MM/yyyy HH:mm:ss'
                )
            return displayMessage('EMPTY_01')
        },
    },
]
