import { useState, useRef, useEffect, useContext } from 'react'
import { Divider, Tooltip } from 'antd'
import { Info, Paperclip, Plus, XCircle } from '@phosphor-icons/react'
import { displayMessage } from '../../helpers/messages'
import { colors } from '../../services/styles'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { SL_Button } from '../buttons/Button'

export function ImportAttachments({
    onChangeAttachments,
    onClose,
    files = null,
    multiple = true,
    fileInputRef = useRef(null),
    info = null,
}) {
    const maxUploadFileSize = 10 * 1024 * 1024
    const { mobile_mode, showAlert } = useContext(GeneralContext)

    const [currentFiles, setCurrentFiles] = useState([])

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const onFileInputChange = event => {
        const { files } = event.target
        handleSetCurrentFile(files)
    }

    const handleSetCurrentFile = filesToSet => {
        let allClear = true
        for (let i = 0; i < filesToSet.length; i++) {
            filesToSet[i].id = Math.random() + Date.now()
            if (filesToSet[i].size > maxUploadFileSize) {
                allClear = false
                let maxSizeText = maxUploadFileSize / 1000000
                showAlert(displayMessage('MAX_ATTACHMENT_SIZE_REACHED', maxSizeText))
            }
        }
        if (allClear) {
            setCurrentFiles(current => current.concat(...filesToSet))
        }
    }

    const removeAttachment = id => {
        let files = [...currentFiles]
        files = files.filter(file => file?.id !== id)
        setCurrentFiles(files)
        if (fileInputRef?.current) {
            fileInputRef.current.value = null
        }
    }

    useEffect(() => {
        const handleBeforeUnload = () => {
            fileInputRef.current.value = null
        }
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    useEffect(() => {
        if (files) {
            if (files.length || files.length == 0) {
                setCurrentFiles(files)
            } else {
                setCurrentFiles([files])
            }
        }
    }, [files])

    useEffect(() => {
        onChangeAttachments(currentFiles)
    }, [currentFiles])
    return (
        <>
            <div className="flex gap-1 text-primary-900">
                {info && (
                    <Tooltip placement="top" title={info} color={colors.secondary[500]}>
                        <Info size={24} />
                    </Tooltip>
                )}
            </div>
            <div className="flex flex-wrap gap-2 bg-primary-100 p-3 items-center w-full max-h-[400px] overflow-auto">
                {currentFiles.map(attachment => (
                    <div
                        key={attachment.id}
                        className={`w-[175px] shadow-md hover:shadow-xl rounded bg-white ${
                            mobile_mode ? 'w-full' : ''
                        }`}
                        style={{ border: `3px dotted ${colors.primary[200]}` }}
                    >
                        <div className="flex justify-end w-full">
                            <Tooltip title={displayMessage('REMOVE_ATTACHMENT')} placement="top">
                                <XCircle
                                    size={23}
                                    className="pt-1 pr-1 cursor-pointer text-sl-red-default"
                                    onClick={() => removeAttachment(attachment.id)}
                                />
                            </Tooltip>
                        </div>
                        <div className="flex items-center justify-center h-[70px]">
                            <Paperclip size={50} weight="thin" />
                        </div>
                        <Divider className="my-1" />
                        <div className="flex items-center justify-center p-2 text-xs">
                            <Tooltip title={attachment.name} placement="top">
                                <span className="truncate">{attachment.name}</span>
                            </Tooltip>
                        </div>
                    </div>
                ))}
                {(multiple || (!multiple && !currentFiles.length)) && (
                    <div
                        className={`flex flex-col items-center justify-center h-[140px] bg-white p-3 gap-3 rounded-lg cursor-pointer ${
                            mobile_mode ? 'w-full' : ''
                        }`}
                        style={{ border: `3px dotted ${colors.primary[200]}` }}
                        onClick={() => onTargetClick()}
                    >
                        <input
                            onChange={onFileInputChange}
                            ref={fileInputRef}
                            type="file"
                            className="hidden"
                            multiple={multiple}
                            accept="image/*"
                        />
                        <Plus size={30} className="font-bold text-sm text-primary-900" />
                        <span className="font-bold text-sm text-primary-900">
                            {displayMessage('ADD_NEW_ATTACHMENT')}
                        </span>
                    </div>
                )}
            </div>
            <div className="flex mt-5 justify-center">
                {currentFiles.length ? (
                    <SL_Button
                        onClick={() => {
                            onClose()
                            onChangeAttachments(currentFiles)
                        }}
                    >
                        {displayMessage('SAVE_ATTACHMENTS')}
                    </SL_Button>
                ) : null}
            </div>
        </>
    )
}
