import React, { useEffect, useState } from 'react'
import { Paperclip } from '@phosphor-icons/react'
import { Divider, Tooltip, Button, Image } from 'antd'
import { colors } from '../../../../../services/styles'
import './chat.css'
import { EyeOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { displayMessage } from '../../../../../helpers/messages'
import api from '../../../../../services/api'

const default_attachments_to_show = 3
const max_attachment_characters = 20
export function ChatMessage({ message, followupReportRisk }) {
    const { description, id, ClientUser, User, Attachments } = message

    const position = ClientUser ? 'end' : 'start'
    const [showAllAttachments, setShowAllAttachments] = useState(false)
    const remainingAttachments =
        Attachments.length > default_attachments_to_show
            ? Attachments.length - default_attachments_to_show
            : 0
    const [attachmentSelected, setAttachmentSelected] = useState(
        followupReportRisk?.Attachment ?? null
    )
    const [pageLoaded, setPageLoaded] = useState(false)
    const [imageVisible, setImageVisible] = useState(null)

    useEffect(() => {
        if (!pageLoaded || !attachmentSelected) {
            setAttachmentSelected(followupReportRisk?.Attachment)
        }
        setPageLoaded(true)
    }, [attachmentSelected])

    const handleOnDownload = async attachment => {
        try {
            const { data } = await api.attachments.openAttachment(attachment.id, {
                responseType: 'blob',
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(data)
            link.download = `${attachment.name}`
            link.click()
            const parent = link.parentNode
            if (link && parent) {
                parent.removeChild(link)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const renderAttachments = () => {
        if (Attachments.length <= default_attachments_to_show || showAllAttachments) {
            return (
                <div className="flex flex-wrap">
                    {Attachments.map((attachment, index) => (
                        <>
                            <div
                                key={index}
                                className={`w-[175px] shadow-md hover:shadow-xl rounded bg-white`}
                                style={{
                                    border: `3px ${
                                        attachmentSelected?.id == attachment.id ? 'solid' : 'dotted'
                                    } ${
                                        attachmentSelected?.id != attachment.id
                                            ? colors.primary[200]
                                            : colors.primary[600]
                                    }`,
                                }}
                            >
                                <Tooltip
                                    title={
                                        attachmentSelected?.id == attachment?.id &&
                                        displayMessage('ATTACHMENT_SELECTED_FOR_RISK')
                                    }
                                    overlayStyle={{ maxWidth: 600 }}
                                    overlayInnerStyle={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        textAlign: 'justify',
                                    }}
                                    className="uppercase font-bold text-white w-full"
                                >
                                    <div className="flex items-center justify-center h-[70px]">
                                        <Paperclip
                                            size={50}
                                            weight="thin"
                                            color={colors.primary[900]}
                                        />
                                    </div>
                                </Tooltip>
                                <Divider className="my-1" />
                                <div className="flex items-center justify-center p-2 text-xs cursor-pointer">
                                    {!attachment.file_type.includes('image') ? (
                                        <a
                                            className="text-center"
                                            key={attachment.id}
                                            href={attachment.url}
                                            download
                                            style={{ display: 'block', marginBottom: '4px' }}
                                            onClick={() => handleOnDownload(attachment)}
                                        >
                                            <Tooltip
                                                title={attachment.name}
                                                overlayStyle={{ maxWidth: 500 }}
                                                overlayInnerStyle={{
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    textAlign: 'justify',
                                                }}
                                            >
                                                {attachment.name.length > max_attachment_characters
                                                    ? `${attachment.name.slice(
                                                          0,
                                                          max_attachment_characters
                                                      )}...`
                                                    : attachment.name}
                                            </Tooltip>
                                        </a>
                                    ) : (
                                        <Tooltip
                                            title={attachment.name}
                                            overlayStyle={{ maxWidth: 500 }}
                                            overlayInnerStyle={{
                                                backgroundColor: 'white',
                                                color: 'black',
                                                textAlign: 'justify',
                                            }}
                                        >
                                            <span className="truncate">
                                                {attachment.name.length > max_attachment_characters
                                                    ? `${attachment.name.slice(
                                                          0,
                                                          max_attachment_characters
                                                      )}...`
                                                    : attachment.name}
                                            </span>
                                        </Tooltip>
                                    )}
                                    {attachment.file_type.includes('image') && (
                                        <div
                                            className="truncate ml-5"
                                            onClick={() => setImageVisible(attachment.id)}
                                        >
                                            <EyeOutlined className="cursor-pointer" />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {imageVisible && (
                                <Image
                                    style={{
                                        display: 'none',
                                    }}
                                    src={api.attachments.imageDownload(imageVisible)}
                                    preview={{
                                        visible: imageVisible ? true : false,
                                        scaleStep: 20,
                                        src: api.attachments.imageDownload(imageVisible),
                                        onVisibleChange: value => {
                                            setImageVisible(value)
                                        },
                                    }}
                                />
                            )}
                        </>
                    ))}
                    {Attachments.length > default_attachments_to_show && (
                        <div
                            className={`w-[175px] h-[115px] flex justify-center items-center shadow-md hover:shadow-xl rounded bg-white`}
                        >
                            <div className="flex items-center justify-center text-xs">
                                <Button
                                    className="text-2xl"
                                    icon={<MinusOutlined />}
                                    type="link"
                                    onClick={() => setShowAllAttachments(false)}
                                ></Button>
                            </div>
                        </div>
                    )}
                </div>
            )
        } else {
            return (
                <div className="flex flex-wrap">
                    {Attachments.slice(0, default_attachments_to_show).map((attachment, index) => (
                        <>
                            <div
                                key={index}
                                className={`w-[175px] shadow-md hover:shadow-xl rounded bg-white`}
                                style={{
                                    border: `3px ${
                                        attachmentSelected?.id == attachment.id ? 'solid' : 'dotted'
                                    } ${
                                        attachmentSelected?.id != attachment.id
                                            ? colors.primary[200]
                                            : colors.primary[600]
                                    }`,
                                }}
                            >
                                <Tooltip
                                    title={
                                        attachmentSelected?.id == attachment?.id &&
                                        displayMessage('ATTACHMENT_SELECTED_FOR_RISK')
                                    }
                                    overlayStyle={{ maxWidth: 600 }}
                                    overlayInnerStyle={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        textAlign: 'justify',
                                    }}
                                    className="uppercase font-bold text-white w-full"
                                >
                                    <div className="flex items-center justify-center h-[70px]">
                                        <Paperclip
                                            size={50}
                                            weight="thin"
                                            color={colors.primary[900]}
                                        />
                                    </div>
                                </Tooltip>
                                <Divider className="my-1" />
                                <div className="flex items-center justify-center p-2 text-xs">
                                    {!attachment.file_type.includes('image') ? (
                                        <a
                                            className="text-center cursor-pointer"
                                            key={attachment.id}
                                            href={attachment.url}
                                            download
                                            style={{ display: 'block', marginBottom: '4px' }}
                                            onClick={() => handleOnDownload(attachment)}
                                        >
                                            <Tooltip
                                                title={attachment.name}
                                                overlayStyle={{ maxWidth: 500 }}
                                                overlayInnerStyle={{
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    textAlign: 'justify',
                                                }}
                                            >
                                                {attachment.name.length > max_attachment_characters
                                                    ? `${attachment.name.slice(
                                                          0,
                                                          max_attachment_characters
                                                      )}...`
                                                    : attachment.name}
                                            </Tooltip>
                                        </a>
                                    ) : (
                                        <Tooltip
                                            title={attachment.name}
                                            overlayStyle={{ maxWidth: 500 }}
                                            overlayInnerStyle={{
                                                backgroundColor: 'white',
                                                color: 'black',
                                                textAlign: 'justify',
                                            }}
                                        >
                                            <span className="truncate">
                                                {attachment.name.length > max_attachment_characters
                                                    ? `${attachment.name.slice(
                                                          0,
                                                          max_attachment_characters
                                                      )}...`
                                                    : attachment.name}
                                            </span>
                                        </Tooltip>
                                    )}
                                    {attachment.file_type.includes('image') && (
                                        <div
                                            className="truncate ml-5 cursor-pointer"
                                            onClick={() => setImageVisible(attachment.id)}
                                        >
                                            <EyeOutlined className="cursor-pointer" />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {imageVisible && (
                                <Image
                                    style={{
                                        display: 'none',
                                    }}
                                    src={api.attachments.imageDownload(imageVisible)}
                                    preview={{
                                        visible: imageVisible ? true : false,
                                        scaleStep: 20,
                                        src: api.attachments.imageDownload(imageVisible),
                                        onVisibleChange: value => {
                                            setImageVisible(value)
                                        },
                                    }}
                                />
                            )}
                        </>
                    ))}
                    <div
                        className={`w-[175px] h-[115px] flex justify-center items-center shadow-md hover:shadow-xl rounded bg-white`}
                        style={{ border: `3px dotted ${colors.primary[200]}` }}
                    >
                        <div className="flex items-center justify-center text-xs">
                            <Button
                                className="text-2xl"
                                icon={<PlusOutlined />}
                                type="link"
                                onClick={() => setShowAllAttachments(true)}
                            >
                                <span className="text-3xl">{remainingAttachments}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
    }
    return (
        <div className={`flex justify-${position} chat-${position}`} id={id}>
            <div className={`${ClientUser ? 'chat-message-system' : 'chat-message-recipient'}`}>
                <div
                    className={`${
                        ClientUser ? 'text-primary-900' : 'text-sl-gray-600'
                    } mb-2 flex justify-between gap-x-5 font-bold text-xs flex-col md:flex-row`}
                >
                    <span className="truncate">
                        {ClientUser?.first_name ? ClientUser.first_name : User?.first_name}
                    </span>
                </div>
                <span>{description}</span>
                <div className="flex flex-wrap justify-center items-center mt-2">
                    {renderAttachments(description)}
                </div>
            </div>
        </div>
    )
}
