import React from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'

export function SL_Info({ children, iconSIze = '30px', size = '80%', className = '' }) {
    return (
        <div className={`${className} flex items-center justify-center w-full`}>
            <div
                className="bg-secondary-100 text-primary-700 p-4 flex rounded"
                role="alert"
                style={{ width: size }}
            >
                <QuestionCircleOutlined className="mr-3" style={{ fontSize: iconSIze }} />
                {children}
            </div>
        </div>
    )
}
