import { notification } from 'antd'
import { axiosInstance } from './axiosInstances'

const path = {
    enums: '/api/client/enums',
    attachments: '/api/client/attachments',
    authClient: '/api/client/auth-client',
    users: '/api/client/client-users',
    notifications: '/api/client/notifications',
    reports: '/api/client/reports',
    reportConfigs: '/api/client/report-configs',
    strengths: '/api/client/strengths',
    risks: '/api/client/risks',
    controlMesures: '/api/client/control-mesures',
    frequencies: '/api/client/frequencies',
    detections: '/api/client/detections',
    severities: '/api/client/severities',
    coverageScales: '/api/client/coverage-scales',
    priorityLevels: '/api/client/priority-levels',
    affectedPillars: '/api/client/affected-pillars',
    riskTypes: '/api/client/risk-types',
    riskActivities: '/api/client/risk-activities',
    riskKinds: '/api/client/risk-kinds',
    reportRisk: '/api/client/report-risks',
    followUps: '/api/client/followups',
    followupReportRisk: '/api/client/followup-report-risks',
    followupApproval: '/api/client/followup-approvals',
    workSectors: '/api/client/work-sectors',
    addresses: '/api/client/addresses',
    clientUserWorkSectors: '/api/client/client-users-worksector',
    clientUserAssociated: 'api/client/client-user-associated',
    clients: '/api/client/clients',
}

export default {
    authClient: {
        authenticate: body => {
            return axiosInstance.post(`${path.authClient}/authenticate`, body)
        },
        currentUser: () => {
            return axiosInstance.get(`${path.authClient}/current-user`)
        },
        resetPasswordSolicitation: email => {
            return axiosInstance.put(`${path.authClient}/reset-password-solicitation`, { email })
        },
        resetPasswordForced: (body, token) => {
            return axiosInstance.put(`${path.authClient}/reset-password-forced`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
        },
        changePassword: (currentPassword, newPassword) => {
            return axiosInstance.put(`${path.authClient}/change-password`, {
                currentPassword,
                newPassword,
            })
        },
    },
    clients: {
        list: params => {
            return axiosInstance.get(`${path.clients}/`, { params })
        },
    },
    clientUserAssociated: {
        list: params => {
            return axiosInstance.get(`${path.clientUserAssociated}/`, { params })
        },
    },
    user: {
        find: id => {
            return axiosInstance.get(`${path.users}/${id}`)
        },
        list: params => {
            return axiosInstance.get(`${path.users}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.users}`, body, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.users}/${id}`, body)
        },
        delete: id => {
            return axiosInstance.put(`${path.users}/${id}`)
        },
        export: body => {
            return axiosInstance.post(`${path.users}/export`, body)
        },
    },
    notifications: {
        list: params => {
            return axiosInstance.get(`${path.notifications}/`, { params })
        },
        readOne: id => {
            return axiosInstance.post(`${path.notifications}/read/${id}`)
        },
        readAll: userId => {
            return axiosInstance.post(`${path.notifications}/read-all/${userId}`)
        },
    },
    reports: {
        list: params => {
            return axiosInstance.get(`${path.reports}/`, { params })
        },
        listClientPainel: params => {
            return axiosInstance.get(`${path.reports}/client-painel`, { params })
        },
        find: id => {
            return axiosInstance.get(`${path.reports}/${id}`)
        },
        finalizate: id => {
            return axiosInstance.post(`${path.reports}/finalization/${id}`)
        },
        open_pdf: (pdfName, config) => {
            return axiosInstance.get(`${path.reports}/pdf_open/${pdfName}`, { ...config })
        },
        generate_pdf: params => {
            return axiosInstance.post(`${path.reports}/pdf/${params.id}/${params.follow_up}`)
        },
    },
    reportConfigs: {
        list: params => {
            return axiosInstance.get(`${path.reportConfigs}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.reportConfigs}/`, body, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
        },
        find: id => {
            return axiosInstance.get(`${path.reportConfigs}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.reportConfigs}/${id}`, body, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
        },
    },
    strengths: {
        list: params => {
            return axiosInstance.get(`${path.strengths}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.strengths}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.strengths}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.strengths}/${id}`, body)
        },
    },
    risks: {
        list: params => {
            return axiosInstance.get(`${path.risks}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.risks}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.risks}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.risks}/${id}`, body)
        },
        vinculeNormatives: (id, body) => {
            return axiosInstance.post(`${path.risks}/vinculate-normative/${id}`, body)
        },
    },
    controlMesures: {
        list: params => {
            return axiosInstance.get(`${path.controlMesures}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.controlMesures}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.controlMesures}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.controlMesures}/${id}`, body)
        },
    },
    frequencies: {
        list: params => {
            return axiosInstance.get(`${path.frequencies}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.frequencies}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.frequencies}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.frequencies}/${id}`, body)
        },
    },
    detections: {
        list: params => {
            return axiosInstance.get(`${path.detections}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.detections}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.detections}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.detections}/${id}`, body)
        },
    },
    severities: {
        list: params => {
            return axiosInstance.get(`${path.severities}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.severities}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.severities}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.severities}/${id}`, body)
        },
    },
    coverageScales: {
        list: params => {
            return axiosInstance.get(`${path.coverageScales}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.coverageScales}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.coverageScales}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.coverageScales}/${id}`, body)
        },
    },
    priorityLevels: {
        list: params => {
            return axiosInstance.get(`${path.priorityLevels}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.priorityLevels}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.priorityLevels}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.priorityLevels}/${id}`, body)
        },
        calculate: params => {
            return axiosInstance.get(`${path.priorityLevels}/calculate-and-find`, { params })
        },
    },
    affectedPillars: {
        list: params => {
            return axiosInstance.get(`${path.affectedPillars}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.affectedPillars}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.affectedPillars}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.affectedPillars}/${id}`, body)
        },
    },
    riskTypes: {
        list: () => {
            return axiosInstance.get(`${path.riskTypes}/`)
        },
    },
    riskActivities: {
        list: params => {
            return axiosInstance.get(`${path.riskActivities}/`, { params })
        },
    },
    riskKinds: {
        list: params => {
            return axiosInstance.get(`${path.riskKinds}/`, { params })
        },
    },
    reportRisks: {
        list: params => {
            return axiosInstance.get(`${path.reportRisk}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.reportRisk}/`, body, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.reportRisk}/${id}`, body, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
        },
        updateGeneralData: (id, body) => {
            return axiosInstance.put(`${path.reportRisk}/update-general-data/${id}`, body)
        },
        updateImage: (id, body) => {
            return axiosInstance.put(`${path.reportRisk}/update-image/${id}`, body, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
        },
        delete: id => {
            return axiosInstance.delete(`${path.reportRisk}/${id}`)
        },
        createFromFollowUp: body => {
            return axiosInstance.post(`${path.reportRisk}/from-followup`, body, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
        },
        find: id => {
            return axiosInstance.get(`${path.reportRisk}/${id}`)
        },
    },
    followUps: {
        list: params => {
            return axiosInstance.get(`${path.followUps}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.followUps}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.followUps}/${id}`)
        },
        finalizate: body => {
            return axiosInstance.post(`${path.followUps}/finalizate`, body)
        },
        finalizateOldCreateNew: body => {
            return axiosInstance.post(`${path.followUps}/finalizate-old-create-new`, body)
        },
    },

    enums: {
        list: () => {
            return axiosInstance.get(`${path.enums}/`)
        },
        find: name => {
            return axiosInstance.get(`${path.enums}/${name}`)
        },
    },

    attachments: {
        createForContent: formData => {
            return axiosInstance.post(`${path.attachments}/content`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
        },
        createForPDF: formData => {
            return axiosInstance.post(`${path.attachments}/pdf`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
        },
        list: params => {
            return axiosInstance.get(`${path.attachments}/list`, { params })
        },
        delete: id => {
            return axiosInstance.delete(`${path.attachments}/${id}`)
        },
        imageDownload: id => {
            return `${process.env.REACT_APP_BACKEND_IP}${path.attachments}/image/download/${id}`
        },
        openAttachment: (id, config) => {
            return axiosInstance.get(`${path.attachments}/attachment-open/${id}`, { ...config })
        },
    },
    followupReportRisk: {
        list: params => {
            return axiosInstance.get(`${path.followupReportRisk}/`, { params })
        },
        find: id => {
            return axiosInstance.get(`${path.followupReportRisk}/${id}`)
        },
        changeStatus: body => {
            return axiosInstance.post(`${path.followupReportRisk}/change-status`, body)
        },
        updateAttachmentRelation: (id, body) => {
            return axiosInstance.post(
                `${path.followupReportRisk}/update-attachment-relation/${id}`,
                body
            )
        },
    },
    followupApprovals: {
        list: params => {
            return axiosInstance.get(`${path.followupApproval}/`, { params })
        },
        find: id => {
            return axiosInstance.get(`${path.followupApproval}/${id}`)
        },
        sendMessage: body => {
            return axiosInstance.post(`${path.followupApproval}/`, body)
        },
    },
    workSectors: {
        list: params => {
            return axiosInstance.get(`${path.workSectors}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.workSectors}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.workSectors}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.workSectors}/${id}`, body)
        },
        export: body => {
            return axiosInstance.post(`${path.workSectors}/export`, body)
        },
    },
    clientUserWorkSectors: {
        list: params => {
            return axiosInstance.get(`${path.clientUserWorkSectors}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.clientUserWorkSectors}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.clientUserWorkSectors}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.clientUserWorkSectors}/${id}`, body)
        },
    },
    addresses: {
        list: params => {
            return axiosInstance.get(`${path.addresses}/`, { params })
        },
        create: body => {
            return axiosInstance.post(`${path.addresses}/`, body)
        },
        find: id => {
            return axiosInstance.get(`${path.addresses}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.addresses}/${id}`, body)
        },
    },
}
