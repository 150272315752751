import { useContext, useEffect, useState } from 'react'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import api from '../../../services/api'
import { Collapse, Tag } from 'antd'
import { AuthContext } from '../../../context/AuthContext/AuthContext'
import { displayMessage } from '../../../helpers/messages'
import { FollowUpDetails } from '../../reports/components/FollowUps/components/FollowUpDetails'
import { DateTime } from 'luxon'
import { useSearchParams } from 'react-router-dom'
import { SL_Select } from '../../../components/selects/Select'
import { SL_Info } from '../../../components/info/Info'

export function FollowUpsScreen({}) {
    const { loggedUser } = useContext(AuthContext)

    const [loading, setLoading] = useState(false)
    const [workSector, setWorkSector] = useState(null)
    const [addresses, setAddresses] = useState([])
    const [followUps, setFollowUps] = useState([])
    const [clients, setClients] = useState([])
    let [searchParams, setSearchParams] = useSearchParams()

    let followup_id = searchParams.get('followup_id')
    let followup_report_risk_id = searchParams.get('followup_report_risk_id')
    const [activeKey, setActiveKey] = useState(searchParams.get('followup_id'))
    const [selectedClientId, setSelectedClientId] = useState(null)

    useEffect(() => {
        loadWorkSector()
        handleOnCollapseChange([followup_id])
        loadClients()
    }, [])

    useEffect(() => {
        if (followup_id) {
            setActiveKey(followup_id)
        }
    }, [searchParams])

    useEffect(() => {
        if (workSector) {
            loadAddresses()
        }
    }, [workSector])

    useEffect(() => {
        if (addresses.length > 0) {
            loadFollowup()
        }
    }, [addresses, selectedClientId])

    const loadClients = async () => {
        try {
            const { data } = await api.clientUserAssociated.list({ client_user_id: loggedUser.id })
            setClients(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadWorkSector = async () => {
        setLoading(true)
        try {
            const { data } = await api.clientUserWorkSectors.list({ client_user_id: loggedUser.id })
            setWorkSector(data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const loadAddresses = async () => {
        setLoading(true)
        try {
            const clients_ids = [...new Set(workSector.map(ws => ws.WorkSector.Client.id))]
            const { data } = await api.addresses.list({
                client_id: clients_ids,
                has_report_finished: true,
            })
            setAddresses(data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const loadFollowup = async () => {
        setLoading(true)
        try {
            let followups = []
            if (loggedUser.type == 1) {
                const { data } = await api.followupReportRisk.list({
                    client_id: selectedClientId ?? null,
                    include_followup: true
                })
                followups = data
            } else {
                const addresses_ids = addresses.map(address => address.id)
                const { data } = await api.followupReportRisk.list({
                    address_id: addresses_ids,
                    client_user_id: loggedUser.id,
                })
                followups = data
            }
            const unique_followups = Array.from(
                new Map(followups.map(item => [item?.Followup?.id, item?.Followup])).values()
            )

            setFollowUps(unique_followups)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    function verifyIfClientReturnedAllReportRisks(followup_report_risks) {
        let all_report_risks_with_return = true
        for (let i = 0; i < followup_report_risks.length; i++) {
            const followup_report_risk = followup_report_risks[i]
            const approvals = followup_report_risk?.FollowupApprovals
            if (approvals.length > 0) {
                let has_client_return = approvals.some(approvals => {
                    return approvals.description && approvals.client_id
                })

                if (!has_client_return) {
                    all_report_risks_with_return = false
                }
            } else {
                all_report_risks_with_return = false
            }
        }

        return all_report_risks_with_return
    }

    const getFollowupFormattedSequence = followup => {
        let sequence,
            max_one_digit = 9,
            max_two_digits = 99
        if (followup.sequence < max_one_digit) {
            sequence = `00${followup.sequence}`
        } else if (followup.sequence < max_two_digits) {
            sequence = `0${followup.sequence}`
        } else {
            sequence = `${followup.sequence}`
        }

        return displayMessage('FOLLOWUP_FORMATTED_SEQUENCE', [sequence])
    }

    const followup_collapse_items = followUps.map((followup, index) => {
        const followup_report_risks = followup?.FollowupReportRisks
        const is_client_returned_all_followups =
            verifyIfClientReturnedAllReportRisks(followup_report_risks)
        const formatted_created_followup = DateTime.fromISO(followup.createdAt).toFormat(
            'dd/MM/yyyy'
        )
        const formatted_sequence = getFollowupFormattedSequence(followup)
        const formatted_finalization_date_programed = followup.finalization_date_programed
            ? DateTime.fromISO(followup.finalization_date_programed).toFormat('dd/MM/yyyy')
            : displayMessage('EMPTY_01')
        const clientName = clients.find(e => e.client.id === followup.Report.Address.client_id)
        const formatted_client_name = clientName
            ? clientName.client.name
            : displayMessage('EMPTY_01')
        return {
            key: followup.id,
            label: (
                <div>
                    {displayMessage('FOLLOWUP_SEQUENCE', [
                        formatted_client_name,
                        formatted_sequence,
                        formatted_created_followup,
                        formatted_finalization_date_programed,
                    ])}
                    {is_client_returned_all_followups ? (
                        <span className="ml-3">
                            {' '}
                            <Tag color="#4db8b8">{displayMessage('INFORMED_HIL')}</Tag>{' '}
                        </span>
                    ) : (
                        <span className="ml-3">
                            {' '}
                            <Tag color="#8e2424">
                                {displayMessage('TAG_RETURN_FOLLOW_UP_REPORT_RISK')}
                            </Tag>{' '}
                        </span>
                    )}
                </div>
            ),
            children: loading ? (
                <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                    <SL_ClipLoader loading={true} />
                </div>
            ) : (
                <div>
                    <FollowUpDetails
                        externFollowUpId={followup?.id}
                        externReportId={followup?.Report?.id}
                        followup_report_risk_id_param={followup_report_risk_id}
                    />
                </div>
            ),
        }
    })

    const handleOnCollapseChange = key => {
        setActiveKey(key)
    }

    return (
        <>
            {loading ? (
                <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                    <SL_ClipLoader loading={true} />
                </div>
            ) : (
                <>
                    {loggedUser.type == 1 && (
                        <>
                            <p className="my-2">{displayMessage('SELECT_ONE_CLIENT')}</p>
                            <SL_Select
                                className="w-1/3 mb-6"
                                options={[
                                    { label: 'Todas', value: null },
                                    ...clients.map(c => ({
                                        label: c.client.name,
                                        value: c.client.id,
                                    })),
                                ]}
                                value={selectedClientId}
                                placeholder={displayMessage('SELECT_ONE_CLIENT')}
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={value => setSelectedClientId(value)}
                            />
                        </>
                    )}
                    <div className="flex w-full">
                        {followup_collapse_items.length ? (
                            <Collapse
                                ghost
                                accordion
                                size="large"
                                className="w-full"
                                items={followup_collapse_items}
                                defaultActiveKey={[followup_id]}
                                activeKey={activeKey}
                                onChange={handleOnCollapseChange}
                            />
                        ) : (
                            <SL_Info iconSIze="50px" className="text-lg" size="50%">
                                <div className="flex justify-center items-center">
                                    <p>{displayMessage('NO_FOLLOW_UPS_INFO')}</p>
                                </div>
                            </SL_Info>
                        )}
                    </div>
                </>
            )}
        </>
    )
}
