import { displayMessage } from '../helpers/messages'

export const ERiskTypes = {
    ACTIVE_PROTECTION: {
        code: 1,
        description: displayMessage('ACTIVE_PROTECTION'),
        acronym: displayMessage('ACTIVE_PROTECTION_ACRONYM'),
    },
    AUDIT_DOCUMENTS: {
        code: 2,
        description: displayMessage('AUDIT_DOCUMENTS'),
        acronym: displayMessage('AUDIT_DOCUMENTS_ACRONYM'),
    },
    INSURANCE_MARKET_REQUIREMENTS: {
        code: 3,
        description: displayMessage('INSURANCE_MARKET_REQUIREMENTS'),
        acronym: displayMessage('INSURANCE_MARKET_REQUIREMENTS_ACRONYM'),
    },
}
